<template>
    <ConfirmDialog></ConfirmDialog>
  <AdminToast></AdminToast>
    <div class='grid'>
        <EmployeeListReport :report='employeeListReport'></EmployeeListReport>
        <div class='col-12 md:col-6 lg:col-12'>
            <div class='p-0 h-full flex flex-column'>
   
                <div class='grid'>
                    <div class='col-12 lg:col-8'>
                        <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> Personel Listesi</h5>
                    </div>
                    <div class='col-12 lg:col-4 text-right'>
                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2'/>
                        <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success' @click='exportExcel'></Button>
                            <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                                <h3>Personel Listesi</h3>
                                <p class="text-lg">
                                    Filtre seçeneğinden proje değiştirererk ilgili projenin personel listesini alabilir üstte yer alan toplamları proje bazında hesaplayabilirsiniz.<br>
                                    <br>
                                    Tüm Projeler seçeneği ile bütün personellerin listesi ve maaş toplamları görüntülenir.<br>
                                    <br>
                                    Excele Aktar fonksiyonu ile tüm listeyi dışarı alabilirsiniz. 
                                </p>
                            </Sidebar>
                    </div>
                </div>
                
                <DataTable :value='dataList' :paginator='true' class='p-datatable-gridlines' :rows='20' dataKey='id' :rowHover='true' v-model:filters='filters' filterDisplay='menu' :loading='loadingIndicator' :filters='filters' responsiveLayout='scroll' :globalFilterFields="['project.name','employee.name','employee.surName','employee.identityNumber','employee.gsm','employee.email','bankName','iban','startDate','endDate','department','employee.gender'] ">

                    <template #header>
                        <div class='grid'>
                            <div class="col-12 lg:col-8 pb-0">
                                <Dropdown :model-value='selectedProject' :options='projectList' option-value='id' option-label='name' @change='projectChange' ></Dropdown>
                            </div>
                            <div class="col-12 lg:col-4 text-right pb-0">
                                <span class='p-input-icon-left mr-2'>
                                    <i class='pi pi-search' />
                                    <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                                </span>
                            </div>
                        </div>
                    </template>

                    <template #empty>
                        Veri bulunamadı.
                    </template>

                    <template #loading>
                        Proje Personel listesi yükleniyor. Lütfen bekleyin.
                    </template>

                    <Column header='Profil' bodyClass="text-center">
                        <template #body='{data}'>
                            <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                        </template>
                    </Column>

                    <Column field='employee.name' :sortable='true' header='Ad'>
                        <template #body='{data}'>
                            <a @click='edit(data)' class="cursor-pointer">
                                {{ data.employee.name }}
                            </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' :sortable='true' header='Soyad'>
                        <template #body='{data}'>
                            <a @click='edit(data)' class="cursor-pointer">
                                {{ data.employee.surName }}
                            </a>
                        </template>
                    </Column>

                    <Column field='employee.identityNumber' header='T.C. Kimlik'>
                    </Column>

                    <Column header='Doğum Tarihi' field='employee.birthday' dataType='employee.birthday' :sortable='true'>
                        <template #body='{data}'>
                            {{ formatDate(data.employee.birthday) }}
                        </template>
                    </Column>

                    <Column header='Cinsiyet' field='employee.gender' dataType='employee.gender' :sortable='true'>
                        <template #body='{data}'>
                            {{ data.employee.gender }}
                        </template>
                    </Column>

                    <Column field='employee.gsm' header='Mesaj' bodyClass='text-center'>
                        <template #body='{data}'>
                            <a :href="'https://wa.me/+90' + data.employee.gsm" v-if='data.employee.gsm != null && data.employee.gsm != ""' target="_blank">
                                <Button icon='pi pi-whatsapp' v-if='data.employee.gsm != null && data.employee.gsm != ""' class='p-button-rounded p-button-success'/>
                            </a>
                        </template>
                    </Column>

                    <Column field='employee.gsm' header='GSM'>
                        <template #body='{data}'>
                            <a :href="'tel:+90' + data.employee.gsm" v-if='data.employee.gsm != null && data.employee.gsm != ""'>{{ data.employee.gsm }}</a>
                        </template>
                    </Column>

                    <Column field='employee.email' header='E-Posta' class="hidden">
                        <template #body='{data}'>
                            <a :href="'mailto:' + data.employee.email" v-if='data.employee.email != null && data.employee.email != ""'>{{ data.employee.email }}</a>
                        </template>
                    </Column>

                    <Column field='project.name' header='Projesi' :sortable='true'>
                    </Column> 

                    <Column header='Departman' field='department' dataType='department' :sortable='true'>
                        <template #body='{data}'>
                            {{ data.department }}
                        </template>
                    </Column> 

                    <Column header='Maaş' field='officialSalary' :sortable='true'>
                        <template #body='slotProps'>
                            <b class="text-green-600">{{ formatPrice(slotProps.data.officialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='Diğer Maaş' field='unOfficialSalary' :sortable='true'>
                        <template #body='slotProps'>
                            <b class="text-red-600">{{ formatPrice(slotProps.data.unOfficialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='Toplam Maaş' field='unOfficialSalary'>
                        <template #body='slotProps'>
                           <b>{{ formatPrice(slotProps.data.unOfficialSalary + slotProps.data.officialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='Banka' class="hidden">
                        <template #body='{data}'>
                            {{data.bankName }}
                        </template>
                    </Column>

                    <Column header='IBAN' class="hidden">
                        <template #body='{data}'>
                            {{ data.iban }}
                        </template>
                    </Column>          

                    <Column header='Giriş Tarihi' field='startDate' :sortable='true' dataType='date'>
                        <template #body='slotProps'>
                            {{ formatDate(slotProps.data.startDate) }}
                        </template>
                    </Column>

                    <Column header='Çıkış Tarihi' field='endDate' dataType='date' :sortable='true'>
                        <template #body='{data}'>
                            {{ formatDate(data.endDate) }}
                        </template>
                    </Column>

                    <Column header='Kayıt Tarihi' field='insertDate' dataType='date' :sortable='true'>
                        <template #body='{data}'>
                            {{ formatDate(data.insertDate) }}
                        </template>
                    </Column>       

                    <Column field='verified' header='Emekli Durum' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isRetired, 'text-pink-500 pi-times-circle': !data.isRetired}"></i>
                        </template>
                    </Column>

                    <Column field='verified' header='SGK Giriş' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isSgkProcessDone, 'text-pink-500 pi-times-circle': !data.isSgkProcessDone}" @click='changeSGKStatus(data.id, ! data.isSgkProcessDone, data.projectEmployeeId)'></i>
                        </template>
                    </Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isActive, 'text-pink-500 pi-times-circle': !data.isActive}"></i>
                        </template>
                    </Column>

                    <Column header="Düzenle" bodyClass="text-center" class="hidden">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary" @click="edit(slotProps.data)"/>
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>

</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import store from '@/store';
import EmployeeListReport from '../../components/shared/EmployeeListReport';
import { checkUserRole, getProjects } from '../common/commonConstantFunctions';
import { showSuccessMessage } from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast.vue';

export default {
    components: { AdminToast, EmployeeListReport },
    data() {
        return {
            visibleLeft:false,
            selectedProject:-1,
            projectList:[],
            dataList: [],
            filters: null,
            loadingIndicator: true,
            salaryReport:{},
            employeeListReport:{
            totalPersonal : 0,
            totalMenCount: 0,
            totalWomenCount: 0,
            totalPrice : 0,
            totalOfficialPrice:0,
            totalUnOfficialPrice:0
            },
        };
    },
    _firmService: null,
    _projectEmployeeService: null,
    created() {
        checkUserRole(this, "Admin");
        this.projectList = getProjects();
        this.projectId = store.getters.getActiveProject.id;
        this._employeeService = new EmployeeService();
        this._projectEmployeeService = new ProjectEmployeeService();
        this.initFilters();
        this.loadingIndicator = true;
    },

    async mounted() {
        await this.refreshProjectEmployeeList();
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        async exportExcel() {
            this.loadingIndicator = true;
            let excelExports = await this._projectEmployeeService.getProjectEmployeesExcel(this.selectedProject);
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Proje Personel Listesi " + moment(this.paymentDate).format('DD.MM.YYYY') + ".xlsx";
            link.click();
            this.loadingIndicator = false;
        },
        async refreshProjectEmployeeList() {
            const response = await this._projectEmployeeService.getProjectEmployees(this.selectedProject);
            if (response.isSuccess) {
                this.dataList = response.data;
                await this.refreshProjectEmployeeReport();
            }

            this.loadingIndicator = false;
        },
        async projectChange(event) {
            this.selectedProject = event.value;
            await this.refreshProjectEmployeeList();
        },
        async refreshProjectEmployeeReport() {
            const response = await this._projectEmployeeService.getProjectEmployeeReports(this.selectedProject);
            if (response.isSuccess) {
                this.employeeListReport = response.data;
            }

            this.loadingIndicator = false;
        },
        clearFilter() {
            this.initFilters();
        },

        formatDate(value) {
            if(value == null)
            {
                return "-";
            }
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        edit(item) {
            this.$router.push({ name: 'editProjectEmployee', params: { employeeId: item.employeeId, projectEmployeeId: item.id } });
        },
        async changeSGKStatus(id, newStatus) {
            this.$confirm.require({
                message: 'SGK Girişi Tamamlandı Statüsünü Değiştirmek istediğinize eminmisiniz??',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let response = await this._projectEmployeeService.updateSGKStatus(id, newStatus);
                    if (response.isSuccess) {
                        showSuccessMessage(this, "SGK Statüsü Değişti");
                    }
                    await this.refreshProjectEmployeeList();
                }
            });

        },
    },
};
</script>


